import React from "react";

export const Features = (props) => {
  return (
    <div id="features" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>Features</h2>
        </div>
        <div className="row">
            <div className="col-xs-6 col-md-3">
              {" "}
              <i className="fa fa-comments-o"></i>
              <h3> <a target="blank" href="https://kdd.conrain.cn">智慧档口</a> </h3>
              <p>农批市场现场交易管理产品</p>
            </div>

            <div className="col-xs-6 col-md-3">
              {" "}
              <i className="fa fa-bullhorn"></i>
              <h3> <a target="blank" href="https://kdd.conrain.cn">线上平台</a> </h3>
              <p>农产品线上化交易与履约配送平台</p>
            </div>

            <div className="col-xs-6 col-md-3">
              {" "}
              <i className="fa fa-group"></i>
              <h3> 产销结合 </h3>
              <p>打通产地到销地的农产品与服务撮合平台</p>
            </div>

            <div className="col-xs-6 col-md-3">
              {" "}
              <i className="fa fa-magic"></i>
              <h3> 智能盘账 </h3>
              <p>批发商的经营管理，客户赊账</p>
              <p>利润分析的智能工具</p>
            </div>
        </div>
      </div>
    </div>
  );
};
